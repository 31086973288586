import { useEffect, useLayoutEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { activeLinkState, showHeaderBgState } from "../state/home";

function debounce<T extends (...args: any[]) => any>(
  ms: number,
  callback: T
): (...args: Parameters<T>) => Promise<ReturnType<T>> {
  let timer: NodeJS.Timeout | undefined;

  return (...args: Parameters<T>) => {
    if (timer) {
      clearTimeout(timer);
    }
    return new Promise<ReturnType<T>>((resolve) => {
      timer = setTimeout(() => {
        const returnValue = callback(...args) as ReturnType<T>;
        resolve(returnValue);
      }, ms);
    });
  };
}

export const useScroll = () => {
  const [activeLink, setActiveLink] = useRecoilState(activeLinkState);
  const [showHeaderBg, setShowHeaderBg] = useRecoilState(showHeaderBgState);
  const [animateState, setAnimateState] = useState<number[]>([]);
  const [sectionsState, setSectionsState] = useState<number[]>([]);
  useEffect(() => {
    window.scrollTo(0, 0);
    const arr: number[] = [];
    const sections = document.querySelectorAll(".home_section");
    const animates = document.querySelectorAll(".animate");
    sections.forEach((section, index) => {
      arr.push(section.getBoundingClientRect().top);
    });
    setSectionsState(arr);

    const arr02: number[] = [];
    animates.forEach((animate, index) => {
      arr02.push(animate.getBoundingClientRect().top - animate.clientHeight);
    });
    setAnimateState(arr02);
  }, []);
  useLayoutEffect(() => {
    const debouncedHandleScroll = debounce(10, () => {
      if (window.scrollY >= 160) {
        setShowHeaderBg(true);
      } else {
        setShowHeaderBg(false);
      }
      const animates = document.querySelectorAll(".animate");
      let currentSectionId = "";
      sectionsState.forEach((sectionTop, index) => {
        if (sectionTop - window.scrollY - 80 <= 0) {
          currentSectionId = `${index}`;
        }
      });
      animateState.forEach((animate, index) => {
        if (index > 0) {
          const visble = window.scrollY + 80 > animate;
          // const visble = window.scrollY > animateState[index - 1];
          if (visble) {
            animates[index].classList.add(
              // "animate__animated",
              // "animate__fadeInUp",
              // "animate__duration-2s"
              "element-from-bottom"
            );
          } else {
            animates[index].classList.remove(
              // "animate__animated",
              // "animate__fadeInUp",
              // "animate__duration-2s"
              "element-from-bottom"
            );
          }
        }
      });
      setActiveLink(currentSectionId);
    });
    window.addEventListener("scroll", debouncedHandleScroll);

    return () => {
      window.removeEventListener("scroll", debouncedHandleScroll);
    };
  }, [JSON.stringify(sectionsState), JSON.stringify(animateState)]);
  return [showHeaderBg, activeLink];
};
