import { isEqual } from "lodash";
import { Children, memo } from "react";
import HomeSection from "../widget/HomeSection/HomeSection";
import Title from "../widget/Title/Title";
import "./Solutions.scss";
import { preStaticUrl } from "../../../../constants/env_config";
import ALink from "../../../../components/ALink/ALink";
import { useCustomTranslation } from "../../../../hooks/useCustomTranslation";
type ISolutionsItem = {
  title: string;
  content: string;
  show?: boolean;
  link?: string;
};
export const SolutionsList: ISolutionsItem[] = [
  {
    show: true,
    title: "Proof of Prompt",
    content:
      "Proof of Prompt enables AI agents to commit to prompts privately while generating verifiable outputs using zero-knowledge proofs. It ensures secure, trustless interactions between AI agents and decentralized applications, allowing on-chain verification without exposing sensitive input data.",
    link: "https://wiki.zypher.network/zypher-ai-agent/zypher-ai-agent/zkprompt",
  },
  {
    show: true,
    title: "Proof of Inference",
    content:
      "Proof of Inference encodes AI models and logic into zk circuits, enabling trustless, privacy-preserving on-chain computations. It guarantees verifiable AI execution with cryptographic proofs of accuracy, ensuring transparency and security. ",
    link: "https://wiki.zypher.network/zypher-ai-agent/zypher-ai-agent/zkinference",
  },
  {
    show: true,
    title: "AI Mining",
    content:
      "Zypher's decentralized prover network generates scalable ZK proofs, supporting AI and blockchain applications with secure, verifiable computations. It operates on an incentivized merged mining model to ensure efficiency and decentralization.",
    link: "https://docs.zypher.network/mining/overview/",
  },
  {
    show: true,
    title: "AI Chain (Zytron)",
    content:
      "Zytron AI Chain is a gasless rollup framework designed for trustless AI agents, optimized for zk-proof verification. Its built-in AI agent templates allow users and developers to deploy AI-powered solutions effortlessly.",
    link: "https://zytron.zypher.network/",
  },
];
const Solutions = memo(() => {
  const { t } = useCustomTranslation();
  return (
    <HomeSection id="solutions" classNames="animate">
      <Title
        title={t("Our Solutions")}
        subTitles={[
          "Powering the AI-driven future, seamlessly.",
          // "Zypher revolutionizes the underlying infrastructure for on-chain trust in high performance applications operated by AI and Human. ",
          // t("catering to a global audience of 3 billion players."),
        ]}
      />
      <div className="solutions_nav">
        {SolutionsList.filter((v) => v.show).map((v) => (
          <SolutionsNavItem item={v} key={v.title} />
        ))}
      </div>
    </HomeSection>
  );
}, isEqual);
const SolutionsNavItem = memo(({ item }: { item: ISolutionsItem }) => {
  const { t } = useCustomTranslation();
  return (
    <ALink
      className="solutions_nav_item"
      href={item.link}
      target="_blank"
      rel="noreferrer"
    >
      <div className="solutions_nav_item_inner">
        <p className="solutions_title">{t(item.title)}</p>
        <p className="solutions_content">{t(item.content)}</p>
        {item.link ? (
          <p className="solutions_more">
            {t("Learn More")}
            <img src={preStaticUrl + "/img/icon/arrow-right.svg"} />
          </p>
        ) : null}
      </div>
      <div className="background-animation" />
    </ALink>
  );
}, isEqual);

export default Solutions;
