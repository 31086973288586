import { memo, useCallback } from "react";
import HomeSection from "../widget/HomeSection/HomeSection";
import Title from "../widget/Title/Title";
import { preStaticUrl } from "../../../../constants/env_config";
import "./AgentStack.scss";
import { useScale } from "./useScale";
const _pre = preStaticUrl + "/img/zypher/agent_stack/";
const AgentStack = memo(
  () => {
    const scale = useScale();
    const linkHandle = useCallback(() => {
      window.open(
        "https://excalidraw.com/#json=XqVnmZdsl45MKWDBJwaXT,gqjbcGjX_wl1eUpDI5I7sQ"
      );
    }, []);
    return (
      <HomeSection id="AgentStack" classNames="animate agent_stack">
        <Title
          title="Our Trustless Agent Stack"
          // subTitles={[
          //   "One or more people create a set of rules in the form of decentralized codes based upon which collective efforts are made to build a community of things.",
          // ]}
        />
        <div
          className="agent_stack_wrap"
          style={{
            transform: `scale(${scale})`,
          }}
        >
          <div className="agent_stack_inner">
            <div className="agent_stack_inner_fl">
              <div className="agent_stack_inner_fl_fl">
                <div
                  className="agent_stack_inner_border agent_stack_inner_border_col1"
                  onClick={linkHandle}
                >
                  <img src={_pre + "/fluent_prompt-16-filled.webp"} />
                  <p>Zk Prompt</p>
                </div>
                <div
                  className="agent_stack_inner_border agent_stack_inner_border_col1"
                  onClick={linkHandle}
                >
                  <img src={_pre + "/ri_chat-ai-line.webp"} />
                  <p>Zk Inference</p>
                </div>
                <div className="agent_stack_inner_border" onClick={linkHandle}>
                  <p>ZKVM</p>
                </div>
                <div className="agent_stack_inner_border" onClick={linkHandle}>
                  <p>Groth16</p>
                </div>
                <div className="agent_stack_inner_border" onClick={linkHandle}>
                  <p>ZKTLS</p>
                </div>
                <div className="agent_stack_inner_border" onClick={linkHandle}>
                  <p>Plonk</p>
                </div>
              </div>
              <div className="agent_stack_inner_fl_fr">
                <div className="agent_stack_inner_border" onClick={linkHandle}>
                  <p>Alpha Girl</p>
                </div>
                <div className="agent_stack_inner_border" onClick={linkHandle}>
                  <p>AI Trusted Agent</p>
                </div>
                <div className="agent_stack_inner_border" onClick={linkHandle}>
                  <p>Expander</p>
                </div>
                <div className="agent_stack_inner_border" onClick={linkHandle}>
                  <p>Nova</p>
                </div>
              </div>
              <div
                className="agent_stack_inner_border agent_stack_inner_col"
                onClick={linkHandle}
              >
                <img src={_pre + "/server.webp"} />
                <p>Proof Mining Protocol</p>
              </div>
              <div
                className="agent_stack_inner_border agent_stack_inner_col"
                onClick={linkHandle}
              >
                <img src={_pre + "/ai.webp"} />
                <p>Trustless AI Agent Proxy Protocol</p>
              </div>
            </div>
            <div className="agent_stack_inner_fr">
              <div className="agent_stack_inner_border" onClick={linkHandle}>
                <h3>Application</h3>
              </div>
              <div className="agent_stack_inner_border" onClick={linkHandle}>
                <h3>Foundation</h3>
              </div>
              <div className="agent_stack_inner_border" onClick={linkHandle}>
                <h3>Protocol</h3>
              </div>
            </div>
          </div>
        </div>
      </HomeSection>
    );
  },
  () => {
    return false;
  }
);
export default AgentStack;
