import "swiper/css";
import "./navigation.scss";
import "./pagination.scss";
import "./homePage.scss";
import Banner from "./components/Banner/Banner";
import Bg from "./components/widget/Bg/Bg";
import Header from "./components/Header/Header";
import { useScroll } from "./hook/useScroll";
import Solutions from "./components/Solutions/Solutions";
import Games from "./components/Games/Games";
import Partners from "./components/Partners/Partners";
import News from "./components/News/News";
import Social from "./components/Social/Social";
import Footer from "./components/Footer/Footer";
import "@reach/dialog/styles.css";
import MenuDialog from "./components/dialog/MenuDialog";
import AgentStack from "./components/AgentStack/AgentStack";
import BackerSection from "./BackerSection/BackerSection";
const DashboardPage = () => {
  useScroll();
  return (
    <>
      <div className="home_p">
        <Header />
        <Banner />
        <Solutions />
        {/* <Rollup /> */}
        {/* <Resources /> */}
        {/* <Evolve /> */}
        {/* <Dao /> */}
        {/* <Manifesto /> */}
        <AgentStack />
        <Games />
        <BackerSection />
        <Partners />
        <News />
        <Social />
        <Footer showLogo={true} />
      </div>
      <Bg />
      <MenuDialog />
    </>
  );
};
export default DashboardPage;
