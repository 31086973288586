import { isEqual } from "lodash";
import { memo, useCallback } from "react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { preStaticUrl } from "../../../../constants/env_config";

import "./Games.scss";
import Title from "../widget/Title/Title";
import Button from "../../../../components/Button/Button";
import { useIsMobile } from "../../../../hooks/useIsMobile";
import { useCustomTranslation } from "../../../../hooks/useCustomTranslation";
import HomeSection from "../widget/HomeSection/HomeSection";
const _preStaticUrl = preStaticUrl + "/img/zypher/games/";

const GameList = [
  {
    bannerImg: _preStaticUrl + "zypher_girl.webp",
    avatarImg: _preStaticUrl + "zypher_girl_avatar.webp",
    title: "Zypher Girl",
    content:
      "The first omnipotent assistant that notarizes everything. Guaranteed by Proof of Prompt.",
  },
  {
    bannerImg: _preStaticUrl + "alpha_girl.webp",
    avatarImg: _preStaticUrl + "alpha_girl_avatar.webp",
    title: "Alpha Girl",
    content:
      "Your multi-modal agent assistant for BTC price predictions, powered by Proof of Prompt. ",
  },
  {
    bannerImg: _preStaticUrl + "ludo.webp",
    avatarImg: _preStaticUrl + "ludo_avatar.webp",
    title: "AI Ludo",
    content:
      "A classic Ludo game where built on-chain. Powered by Proof Anti-collusion. ",
  },
  {
    bannerImg: _preStaticUrl + "DCO.webp",
    avatarImg: _preStaticUrl + "DCO_avatar.webp",
    title: "Divine Cataclysm : Oblivion",
    content:
      "In the world of cards, heroes and deities weave their destinies, and wisdom and decisions forge legends...",
  },
  {
    link: "https://zytron-linea.acequest.io/zAce/",
    bannerImg: _preStaticUrl + "zAce.webp",
    avatarImg: _preStaticUrl + "zAce_avatar.webp",
    title: "zAce Poker",
    content:
      "Discover zACE, the fairest and most exciting Texas Hold'em with radical transparency.",
  },
  {
    link: "https://zypher.game/bingo/",
    bannerImg: _preStaticUrl + "zBingo.webp",
    avatarImg: _preStaticUrl + "zBingo_avatar.webp",
    title: "zBingo",
    content:
      "zBingo employs advanced Zero-Knowledge Proofs (ZKPs) to ensure provably fair gameplay and leverages artificial intelligence for quick and efficient matchmaking.",
  },
  {
    link: "https://zypher.game/2048/",
    bannerImg: _preStaticUrl + "z2048.webp",
    avatarImg: _preStaticUrl + "z2048_avatar.webp",
    title: "z2048",
    content:
      "Immerse yourself in an evolutionary gaming journey with z2048, seamlessly integrating the quintessential mechanics of the classic 2048 puzzle with an autonomous fully on-chain methodology.",
  },
  {
    link: "https://test.zypher.game/CryptoRumble/",
    bannerImg: _preStaticUrl + "CR.webp",
    avatarImg: _preStaticUrl + "CR_avatar.webp",
    title: "Crypto Rumble",
    content:
      "BNB Chain Hackathon Istanbul 1st place winner. The first zkp-powered, fully on-chain tile-matching game.",
  },
];
const Games = memo(() => {
  const { t } = useCustomTranslation();
  const isMobile = useIsMobile();
  const onclikHandle = useCallback((link?: string) => {
    if (link) {
      window.open(link, "_blank");
    }
  }, []);
  return (
    <HomeSection id="Games" classNames="animate games">
      <Title title="Featured DAIpps" />
      <div className="game_swiper_wrap">
        <Swiper
          direction="horizontal"
          loop={true}
          slidesPerView={isMobile ? 1.4 : 1.8}
          spaceBetween={isMobile ? 16 : 24}
          centeredSlides={true}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          navigation={isMobile ? false : true}
          pagination={isMobile ? true : false}
          modules={isMobile ? [Autoplay, Pagination] : [Autoplay, Navigation]}
          className="game_swiper"
        >
          {GameList.map((v, index) => (
            <SwiperSlide key={index}>
              <>
                <div
                  className="game_swiper_item"
                  onClick={() => onclikHandle(v.link)}
                >
                  <img
                    src={v.bannerImg}
                    alt={v.title}
                    className="game_swiper_img"
                  />
                  <div className="game_swiper_bottom">
                    {isMobile ? null : (
                      <img
                        className="game_swiper_bottom_avatar"
                        src={v.avatarImg}
                        alt={v.title}
                      />
                    )}
                    <div className="game_swiper_bottom_fr">
                      <div className="game_swiper_bottom_inner">
                        {isMobile ? (
                          <img
                            className="game_swiper_bottom_avatar"
                            src={v.avatarImg}
                            alt={v.title}
                          />
                        ) : null}
                        <div className="game_swiper_bottom_inner_inner">
                          <p className="game_swiper_bottom_title">{v.title}</p>
                          <p className="game_swiper_bottom_content">
                            {t(v.content)}
                          </p>
                        </div>
                      </div>
                      <Button
                        onClick={() => onclikHandle(v.link)}
                        classNameInner="game_swiper_bottom_btn"
                        label={t("Play Now")}
                      />
                    </div>
                  </div>
                </div>
                <img
                  src={v.bannerImg}
                  alt={v.title}
                  className="game_swiper_img_big"
                />
              </>
            </SwiperSlide>
          ))}
        </Swiper>
        {/* <div className="games_bg1" />
        <div className="games_bg2" /> */}
      </div>
    </HomeSection>
  );
}, isEqual);
export default Games;
