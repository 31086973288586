import { memo } from "react";
import HomeSection from "../components/widget/HomeSection/HomeSection";
import Title from "../components/widget/Title/Title";
import { preStaticUrl } from "../../../constants/env_config";
import "./BackerSection.scss";
const arr = [
  preStaticUrl + "/img/zypher/backer_section/Catchervc.webp",
  preStaticUrl + "/img/zypher/backer_section/Cogitent.webp",
  preStaticUrl + "/img/zypher/backer_section/DWF.webp",
  preStaticUrl + "/img/zypher/backer_section/Gevulot.webp",
  preStaticUrl + "/img/zypher/backer_section/HongLeong.webp",
  preStaticUrl + "/img/zypher/backer_section/Mantle.webp",
  preStaticUrl + "/img/zypher/backer_section/Signum.webp",
  preStaticUrl + "/img/zypher/backer_section/UOB.webp",
];
const BackerSection = memo(
  () => {
    return (
      <HomeSection id="BackerSection" classNames="backer_section">
        <Title title="Backers" />
        <div className="backer_section_inner">
          {arr.map((v) => (
            <img src={v} key={v} />
          ))}
        </div>
      </HomeSection>
    );
  },
  () => false
);
export default BackerSection;
